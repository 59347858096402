<template>
  <div>
    <div v-for="block in blocks" :key="block.id">
      <figure v-if="block.scheme === 'image' && block.attributes.src">
        <div
          :class="{
            'embed-responsive embed-responsive-4by3':
              block.attributes.aspect === '4/3',
            'embed-responsive embed-responsive-16by9':
              block.attributes.aspect === '16/9',
            'embed-responsive embed-responsive-1by1':
              block.attributes.aspect === '1/1',
            'embed-responsive embed-responsive-21by9':
              block.attributes.aspect === '21/9',
            'img-fluid': block.attributes.aspect === 'fluid',
          }"
        >
          <a
            v-if="block.attributes.href"
            :href="block.attributes.href"
            :target="block.attributes.href_target"
            rel="noopener noreferrer"
          >
            <img
              :src="block.attributes.src"
              :class="{
                'img-fluid': block.attributes.aspect === 'fluid',
                'embed-responsive-item': block.attributes.aspect !== 'fluid',
              }"
              :style="`object-fit: ${block.attributes.fit}`"
            />
          </a>
          <img
            v-else
            :src="block.attributes.src"
            :class="{
              'img-fluid': block.attributes.aspect === 'fluid',
              'embed-responsive-item': block.attributes.aspect !== 'fluid',
            }"
            :style="`object-fit: ${block.attributes.fit}`"
          />
        </div>
        <figcaption
          v-if="block.attributes.title || block.attributes.credentials"
          :class="block.attributes.credentials_align"
        >
          <span v-if="block.attributes.title">
            {{ block.attributes.title }}
          </span>
          <span v-if="block.attributes.credentials">
            <br />
            {{ block.attributes.credentials }}
          </span>
        </figcaption>
      </figure>
      <figure
        v-if="block.scheme === 'carousel' && block.attributes.slides.length > 0"
      >
        <div
          :class="{
            'embed-responsive embed-responsive-4by3':
              block.attributes.aspect === '4/3',
            'embed-responsive embed-responsive-16by9':
              block.attributes.aspect === '16/9',
            'embed-responsive embed-responsive-1by1':
              block.attributes.aspect === '1/1',
            'embed-responsive embed-responsive-21by9':
              block.attributes.aspect === '21/9',
            'img-fluid': block.attributes.aspect === 'fluid',
          }"
        >
          <b-carousel
            :interval="parseInt(block.attributes.interval)"
            controls
            indicators
            background="#ababab"
            img-width="100%"
            img-height="100%"
            style="text-shadow: 1px 1px 2px #333"
            class="embed-responsive-item"
          >
            <b-carousel-slide
              v-for="(slide, key) in block.attributes.slides"
              :key="key"
              :caption="slide.title"
              :text="slide.description"
              :img-src="slide.src"
            />
          </b-carousel>
        </div>
        <figcaption
          v-if="block.attributes.title || block.attributes.credentials"
          :class="block.attributes.credentials_align"
        >
          <span v-if="block.attributes.title">
            {{ block.attributes.title }}
          </span>
          <span v-if="block.attributes.credentials">
            <br />
            {{ block.attributes.credentials }}
          </span>
        </figcaption>
      </figure>
      <figure v-if="block.scheme === 'audio' && block.attributes.src">
        <audio
          controls
          :src="block.attributes.src"
          class="flux-editor-audio-player"
        >
          <p>
            Votre navigateur ne prend pas en charge l'audio HTML. Voici un un
            <a :href="block.attributes.src">lien vers le fichier audio</a> pour
            le télécharger.
          </p>
        </audio>
        <figcaption
          v-if="block.attributes.title || block.attributes.credentials"
          :class="block.attributes.credentials_align"
        >
          <span v-if="block.attributes.title">
            {{ block.attributes.title }}
          </span>
          <span v-if="block.attributes.credentials">
            <br />
            {{ block.attributes.credentials }}
          </span>
        </figcaption>
      </figure>
      <figure
        v-if="block.scheme === 'video' && block.attributes.id"
        class="flux-editor-video-container"
      >
        <div
          :class="{
            'embed-responsive embed-responsive-4by3':
              block.attributes.aspect === '4/3',
            'embed-responsive embed-responsive-16by9':
              block.attributes.aspect === '16/9',
            'embed-responsive embed-responsive-1by1':
              block.attributes.aspect === '1/1',
            'embed-responsive embed-responsive-21by9':
              block.attributes.aspect === '21/9',
          }"
        >
          <iframe
            v-if="block.attributes.source === 'YOUTUBE'"
            width="100%"
            height="100%"
            class="embed-responsive-item"
            :src="`https://www.youtube-nocookie.com/embed/${block.attributes.id}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            v-if="block.attributes.source === 'PEERTUBE'"
            width="100%"
            height="100%"
            title="Peertube video player"
            sandbox="allow-same-origin allow-scripts allow-popups"
            :src="`https://video.blast-info.fr/videos/embed/${block.attributes.id}`"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
        <figcaption
          v-if="block.attributes.title || block.attributes.credentials"
          :class="block.attributes.credentials_align"
        >
          <span v-if="block.attributes.title">
            {{ block.attributes.title }}
          </span>
          <span v-if="block.attributes.credentials">
            <br />
            {{ block.attributes.credentials }}
          </span>
        </figcaption>
      </figure>
      <div v-if="block.scheme === 'text'" v-html="block.attributes.source" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'StoryContentSection',
  props: {
    blocks: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
