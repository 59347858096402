<template>
  <div>
    <story-content-section :blocks="blocks" />
    <div v-if="offBlocks.length > 0" class="content-off">
      <h2>Le off de l’enquête Blast</h2>
      <div class="content-off-body">
        <story-content-section :blocks="offBlocks" class="content-off" />
      </div>
    </div>
  </div>
</template>
<script>
import StoryContentSection from '@/components/StoryContentSection.vue'

export default {
  name: 'StoryContent',
  components: { StoryContentSection },
  props: {
    content: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      blocks: [],
      offBlocks: [],
    }
  },
  created() {
    let section = null
    if (this.content && this.content.blocks) {
      // console.log(JSON.parse(JSON.stringify(this.content.blocks)))
      this.content.blocks.forEach((block) => {
        if (block.scheme === 'section' && block.attributes.type === 'off') {
          section = true
        }
        if (section === null) {
          this.blocks.push(block)
        } else {
          this.offBlocks.push(block)
        }
      })
    }
    // for (const block of this.content.blocks) {
    //   if (block.scheme === 'section' && block.attributes.type === 'off') {
    //     section = true
    //   }
    //   if (section === null) {
    //     this.blocks.push(block)
    //   } else {
    //     this.offBlocks.push(block)
    //   }
    // }
  },
}
</script>
