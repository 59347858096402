var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.blocks),function(block){return _c('div',{key:block.id},[(block.scheme === 'image' && block.attributes.src)?_c('figure',[_c('div',{class:{
          'embed-responsive embed-responsive-4by3':
            block.attributes.aspect === '4/3',
          'embed-responsive embed-responsive-16by9':
            block.attributes.aspect === '16/9',
          'embed-responsive embed-responsive-1by1':
            block.attributes.aspect === '1/1',
          'embed-responsive embed-responsive-21by9':
            block.attributes.aspect === '21/9',
          'img-fluid': block.attributes.aspect === 'fluid',
        }},[(block.attributes.href)?_c('a',{attrs:{"href":block.attributes.href,"target":block.attributes.href_target,"rel":"noopener noreferrer"}},[_c('img',{class:{
              'img-fluid': block.attributes.aspect === 'fluid',
              'embed-responsive-item': block.attributes.aspect !== 'fluid',
            },style:(("object-fit: " + (block.attributes.fit))),attrs:{"src":block.attributes.src}})]):_c('img',{class:{
            'img-fluid': block.attributes.aspect === 'fluid',
            'embed-responsive-item': block.attributes.aspect !== 'fluid',
          },style:(("object-fit: " + (block.attributes.fit))),attrs:{"src":block.attributes.src}})]),(block.attributes.title || block.attributes.credentials)?_c('figcaption',{class:block.attributes.credentials_align},[(block.attributes.title)?_c('span',[_vm._v(" "+_vm._s(block.attributes.title)+" ")]):_vm._e(),(block.attributes.credentials)?_c('span',[_c('br'),_vm._v(" "+_vm._s(block.attributes.credentials)+" ")]):_vm._e()]):_vm._e()]):_vm._e(),(block.scheme === 'carousel' && block.attributes.slides.length > 0)?_c('figure',[_c('div',{class:{
          'embed-responsive embed-responsive-4by3':
            block.attributes.aspect === '4/3',
          'embed-responsive embed-responsive-16by9':
            block.attributes.aspect === '16/9',
          'embed-responsive embed-responsive-1by1':
            block.attributes.aspect === '1/1',
          'embed-responsive embed-responsive-21by9':
            block.attributes.aspect === '21/9',
          'img-fluid': block.attributes.aspect === 'fluid',
        }},[_c('b-carousel',{staticClass:"embed-responsive-item",staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"interval":parseInt(block.attributes.interval),"controls":"","indicators":"","background":"#ababab","img-width":"100%","img-height":"100%"}},_vm._l((block.attributes.slides),function(slide,key){return _c('b-carousel-slide',{key:key,attrs:{"caption":slide.title,"text":slide.description,"img-src":slide.src}})}),1)],1),(block.attributes.title || block.attributes.credentials)?_c('figcaption',{class:block.attributes.credentials_align},[(block.attributes.title)?_c('span',[_vm._v(" "+_vm._s(block.attributes.title)+" ")]):_vm._e(),(block.attributes.credentials)?_c('span',[_c('br'),_vm._v(" "+_vm._s(block.attributes.credentials)+" ")]):_vm._e()]):_vm._e()]):_vm._e(),(block.scheme === 'audio' && block.attributes.src)?_c('figure',[_c('audio',{staticClass:"flux-editor-audio-player",attrs:{"controls":"","src":block.attributes.src}},[_c('p',[_vm._v(" Votre navigateur ne prend pas en charge l'audio HTML. Voici un un "),_c('a',{attrs:{"href":block.attributes.src}},[_vm._v("lien vers le fichier audio")]),_vm._v(" pour le télécharger. ")])]),(block.attributes.title || block.attributes.credentials)?_c('figcaption',{class:block.attributes.credentials_align},[(block.attributes.title)?_c('span',[_vm._v(" "+_vm._s(block.attributes.title)+" ")]):_vm._e(),(block.attributes.credentials)?_c('span',[_c('br'),_vm._v(" "+_vm._s(block.attributes.credentials)+" ")]):_vm._e()]):_vm._e()]):_vm._e(),(block.scheme === 'video' && block.attributes.id)?_c('figure',{staticClass:"flux-editor-video-container"},[_c('div',{class:{
          'embed-responsive embed-responsive-4by3':
            block.attributes.aspect === '4/3',
          'embed-responsive embed-responsive-16by9':
            block.attributes.aspect === '16/9',
          'embed-responsive embed-responsive-1by1':
            block.attributes.aspect === '1/1',
          'embed-responsive embed-responsive-21by9':
            block.attributes.aspect === '21/9',
        }},[(block.attributes.source === 'YOUTUBE')?_c('iframe',{staticClass:"embed-responsive-item",attrs:{"width":"100%","height":"100%","src":("https://www.youtube-nocookie.com/embed/" + (block.attributes.id)),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e(),(block.attributes.source === 'PEERTUBE')?_c('iframe',{attrs:{"width":"100%","height":"100%","title":"Peertube video player","sandbox":"allow-same-origin allow-scripts allow-popups","src":("https://video.blast-info.fr/videos/embed/" + (block.attributes.id)),"frameborder":"0","allowfullscreen":""}}):_vm._e()]),(block.attributes.title || block.attributes.credentials)?_c('figcaption',{class:block.attributes.credentials_align},[(block.attributes.title)?_c('span',[_vm._v(" "+_vm._s(block.attributes.title)+" ")]):_vm._e(),(block.attributes.credentials)?_c('span',[_c('br'),_vm._v(" "+_vm._s(block.attributes.credentials)+" ")]):_vm._e()]):_vm._e()]):_vm._e(),(block.scheme === 'text')?_c('div',{domProps:{"innerHTML":_vm._s(block.attributes.source)}}):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }