<template>
  <div>
    <b-card v-if="story.id">
      <div class="row">
        <div class="col-lg-8">
          <story-title :story="story" />
        </div>
        <div class="col-lg-4 text-right">
          <a
            :href="story.canonical_url"
            target="_blank"
            class="btn btn-info btn-sm mr-1"
          >
            <font-awesome-icon icon="eye" />
            Voir la page
          </a>
          <router-link
            :to="`/stories/${story.id}/edit`"
            class="btn btn-primary btn-sm"
          >
            <font-awesome-icon icon="pencil-alt" />
            Editeur
          </router-link>
        </div>
      </div>
      <b-tabs content-class="mt-2">
        <b-tab title="Propriétés" active>
          <div class="row">
            <div class="col-lg-11">
              <story-form
                v-if="displayStoryForm == true"
                :story-id="story.id"
                :display.sync="displayStoryForm"
                @updated="onUpdate"
                @deleted="onDelete"
              />
              <story-card v-else :story="story" />
            </div>
            <div class="col-lg-1">
              <button
                class="btn btn-primary btn-sm"
                @click.prevent="displayStoryForm = !displayStoryForm"
              >
                <feather-icon icon="EditIcon" />
              </button>
            </div>
          </div>
        </b-tab>
        <b-tab title="Illustration">
          <story-cover :story="story" :edit.sync="editCover" @uploaded="init" />
        </b-tab>
        <b-tab title="Métadonnées">
          <pre>{{ story }}</pre>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card v-if="story.id">
      <div class="row">
        <div class="col-lg-8 pl-5 pr-5">
          <h4>Chapô</h4>
          <p class="lead pt-1 pb-2">
            {{ story.headline }}
          </p>
          <h4>Article</h4>
          <hr />
          <story-content
            v-if="
              story.content_blocks &&
              story.content_blocks.blocks &&
              story.content_blocks.blocks.length > 0
            "
            :content="story.content_blocks"
            class="story-content pt-1"
          />
        </div>
        <div class="col-lg-4 text-right">
          <b-img :src="`${story.thumb_hero_url}?d=${new Date()}`" fluid />
          <p>
            <span v-if="story.cover_image_credits">
              {{ story.cover_image_credits }}
            </span>
            <span v-else>
              <em class="text-muted">Pas de crédits</em>
            </span>
          </p>
        </div>
      </div>
    </b-card>
    <!-- <pre>{{ story }}</pre> -->
  </div>
</template>

<script>
import { BCard, BTabs, BTab, BImg } from 'bootstrap-vue'
import StoryCover from '@/components/StoryCover.vue'
import StoryTitle from '@/components/StoryTitle.vue'
import StoryCard from '@/components/StoryCard.vue'
import StoryContent from '@/components/StoryContent.vue'
import StoryForm from '@/components/StoryForm.vue'

const storyFields =
  'id,title,rich_title,headline,extract,content_with_post_rendering,status,canonical_url,scheme,' +
  'content_blocks,cover_image_credits,cover_image_url,thumb_hero_url,' +
  'scope,primary_category{id,label},created_at,updated_at,thumbnails_formats,' +
  'content_type,slug,comments_allowed,published_at,is_published,' +
  'authors{id,screenname},' +
  'attachments,attachments_by_keys,attributes,' +
  'publication_rules,' +
  'story_tags{id,label},contextual_stories{id,title},contextual_carousel{id,title},' +
  'story_categories{id,label},' +
  'related_stories{id,title,thumb_story_list_url,primary_category{label},created_at,published_at}'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    StoryCover,
    StoryTitle,
    StoryCard,
    StoryContent,
    StoryForm,
    BImg,
  },
  data() {
    return {
      displayStoryForm: false,
      editCover: false,
      story: {},
    }
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get(`/stories/${this.$route.params.id}/`, {
        headers: {
          'X-Fields': storyFields,
        },
      })
      this.story = resp.data
    },
    onUpdate() {
      this.init()
    },
    onDelete() {},
  },
}
</script>
