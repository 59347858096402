<template>
  <div class="row">
    <dl class="row">
      <dt class="col-md-3 text-muted text-md-right">ID</dt>
      <dd class="col-md-9">
        <b><copy-value :value="story.id" /></b>
      </dd>
      <dt class="col-md-3 text-muted text-md-right">Slug</dt>
      <dd class="col-md-9">
        <b><copy-value :value="story.slug" /></b>
      </dd>
      <dt class="col-md-3 text-muted text-md-right">Création</dt>
      <dd class="col-md-9">
        {{ $dayjs(story.created_at).format('DD-MM-YYYY') }}
      </dd>
      <dt class="col-md-3 text-muted text-md-right">Mise à jour</dt>
      <dd class="col-md-9">
        {{ $dayjs(story.updated_at).format('DD-MM-YYYY à HH:mm') }}
      </dd>
      <dt class="col-md-3 text-muted text-md-right">Publié le</dt>
      <dd class="col-md-9">
        {{ $dayjs(story.published_at).format('DD-MM-YYYY à HH:mm') }}
      </dd>
      <dt class="col-md-3 text-muted text-md-right">Type</dt>
      <dd class="col-md-9">
        {{ $t(`STORY.${story.scheme}`) }}
      </dd>
      <dt class="col-md-3 text-muted text-md-right">Visibilité</dt>
      <dd class="col-md-9">
        {{ $t(`STORY.${story.scope}`) }}
      </dd>
      <dt class="col-md-3 text-muted text-md-right">Rédacteur</dt>
      <dd class="col-md-9">
        <span v-if="story.authors && story.authors.length > 0">
          {{
            story.authors
              .map((t) => {
                return t
              })
              .join(', ')
          }}
        </span>
        <span v-else>-</span>
      </dd>
      <dt class="col-md-3 text-muted text-md-right">Rubrique</dt>
      <dd class="col-md-9">
        <span v-if="story.primary_category">
          {{ story.primary_category.label }}
        </span>
      </dd>
      <!-- <dt class="col-md-3 text-muted text-md-right">Tags</dt>
      <dd class="col-md-9">
        {{
          story.story_tags
            .map((t) => {
              return t.label
            })
            .join(', ')
        }}
      </dd> -->
    </dl>
  </div>
</template>

<script>
export default {
  name: 'StoryCard',
  components: {},
  props: {
    story: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>
